<template>
  <div>
    <div v-if="$userInfo.isSupportUser || $userInfo.isCustomerAdministrator">
      <app-detail-header :show-toggle="true"
        :show-new="false"
        :show-print="false"
        :show-delete="false"
        :show-save="false"
        :show-cancel="false" />
      <div class="columns is-gapless">
        <div class="column is-narrow">
          <company-setting-side-menu />
        </div>
        <div class="column">
          <router-view ref="currentChild" />
        </div>
      </div>
    </div>
    <div v-else
      class="is-flex is-justify-content-center pt-5 ">
      <div class="notification is-danger is-flex is-justify-content-center is-size-4"
        style="width: 50%">
        You do not have permissions on this page
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import CompanySettingSideMenu from './CompanySettingSideMenu'

export default {
  name: 'CompanySettingView',
  components: {
    AppDetailHeader,
    CompanySettingSideMenu
  }
}
</script>
